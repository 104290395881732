import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faHome, faScaleUnbalancedFlip, faStore, faStoreAlt, faCog, faUsers ,faObjectGroup, faImages, faTableColumns, faAdd, faFileImport, faKey, faPoll , faBarcode, faVihara, faBolt} from '@fortawesome/free-solid-svg-icons'
import './sidebar.css';


function Sidebar({member}) {
    const [sideClass, setSideClass] = useState("full-side");
  //console.log("Side Content");
 // console.log(member);
    
  return (
    <div className={"bs-side " + sideClass}>
        <div className="sidebarMenu">
            <h3 className="sidebarTitle logo-spark">Dashboard</h3>  
            <div className="sidebarTitle logo-mobile"><FontAwesomeIcon icon={faBarcode} /></div>  
            <ul>
                <li className="sidebarListItem">
                    <div className="col-item-block">
                        <Link to="/" className="link-nav-item-side">
                        <span className="sd-icon"><FontAwesomeIcon icon={faHome} /></span> 
                        <span className="name-Item">HOME</span>
                        </Link>
                    </div>
                </li>
                {
                    member.isAdmin &&(
                        <li className="sidebarListItem">
                            <div className="col-item-block">
                            <Link to="/library" className="link-nav-item-side">
                                    <span className="sd-icon"> <FontAwesomeIcon icon={faImages} /></span> 
                                    <span className="name-Item">Library</span>
                                </Link>
                            </div>
                        </li>                        
                    )
                }
                 {
                    member.isAdmin &&(
                    <li className="sidebarListItem">
                        <div className="col-item-block">
                        <Link to="/media" className="link-nav-item-side">
                                <span className="sd-icon"> <FontAwesomeIcon icon={faPoll} /></span> 
                                <span className="name-Item">Media</span>
                            </Link>
                        </div>
                    </li>
                    )
                }
                {
                    ((member.roles === "supplier")  || (member.isAdmin)) &&(
                        <li className="sidebarListItem">
                            <div className="col-item-block">
                            <Link to="/addphotos" className="link-nav-item-side">
                                    <span className="sd-icon"> <FontAwesomeIcon icon={faObjectGroup} /></span> 
                                    <span className="name-Item">Add Photos</span>
                                </Link>
                            </div>
                        </li>
                        )
                }
                {
                    member.isAdmin &&(
                        <li className="sidebarListItem">
                            <div className="col-item-block">
                            <Link to="/categories" className="link-nav-item-side">
                                    <span className="sd-icon"> <FontAwesomeIcon icon={faVihara} /></span> 
                                    <span className="name-Item">Categories</span>
                                </Link>
                            </div>
                        </li>
                        )
                }
                {
                  /*
                    member.isAdmin &&(
                        <li className="sidebarListItem">
                            <div className="col-item-block">
                            <Link to="/imports" className="link-nav-item-side">
                                    <span className="sd-icon"> <FontAwesomeIcon icon={faFileImport} /></span> 
                                    <span className="name-Item">Import File</span>
                                </Link>
                            </div>
                        </li>
                        )
                  */ }
                {
                    ((member.roles === "standard")  || (member.isAdmin)) &&(
                        <li className="sidebarListItem">
                            <div className="col-item-block">
                            <Link to="/catalogue" className="link-nav-item-side">
                                    <span className="sd-icon"> <FontAwesomeIcon icon={faTableColumns} /></span> 
                                    <span className="name-Item">Catalogue</span>
                                </Link>
                            </div>
                        </li>
                    )
                }
               
                {
                    member.isAdmin &&(
                <li className="sidebarListItem">
                    <div className="col-item-block">
                       <Link to="/add-catalogue" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faAdd} /></span> 
                            <span className="name-Item">Add Catalogue</span>
                        </Link>
                    </div>
                </li>
                )
                }
                {
                    ((member.roles === "supplier")  || (member.isAdmin)) &&(
                <li className="sidebarListItem">
                    <div className="col-item-block">
                       <Link to="/suppliers" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faStore} /></span> 
                            <span className="name-Item">Suppliers</span>
                        </Link>
                    </div>
                </li> 
                    )
                }
              
                {
                    member.isAdmin &&(
                <li className="sidebarListItem">
                    <div className="col-item-block">
                       <Link to="/add-supplier" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faStoreAlt} /></span> 
                            <span className="name-Item">Add Supplier</span>
                        </Link>
                    </div>
                </li>
                 )
                }
                {
                    member.isAdmin &&(
                    <li className="sidebarListItem">
                        <div className="col-item-block">
                        <Link to="/users" className="link-nav-item-side">
                                <span className="sd-icon"> <FontAwesomeIcon icon={faUsers} /></span> 
                                <span className="name-Item">Users</span>
                            </Link>
                        </div>
                    </li>
                    )
                }
                {
                    /*
                    member.isAdmin && (
                        <li className="sidebarListItem">
                            <div className="col-item-block">
                               <Link to="/pipeline" className="link-nav-item-side">
                                    <span className="sd-icon"> <FontAwesomeIcon icon={faPoll} /></span> 
                                    <span className="name-Item">Pipeline</span>
                                </Link>
                            </div>
                        </li>
                    )
                    */
                }                
                {
                    member.isAdmin &&(
                    <li className="sidebarListItem">
                        <div className="col-item-block">
                        <Link to="/saleslayer" className="link-nav-item-side">
                                <span className="sd-icon"> <FontAwesomeIcon icon={faScaleUnbalancedFlip} /></span> 
                                <span className="name-Item">SalesLayer</span>
                            </Link>
                        </div>
                    </li>
                    )
                }
                {
                    member.isAdmin &&(
                <li className="sidebarListItem">
                    <div className="col-item-block">
                       <Link to="/tokens" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faKey} /></span> 
                            <span className="name-Item">Tokens</span>
                        </Link>
                    </div>
                </li>
                  )
                }
                <li className="sidebarListItem">
                    <div className="col-item-block">
                       <Link to="/knowledge-base" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faBolt} /></span> 
                            <span className="name-Item">Knowledge Base</span>
                        </Link>
                    </div>
                </li>
                {
                    member.isAdmin &&(
                <li className="sidebarListItem">
                    <div className="col-item-block">
                       <Link to="/settings" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faCog} /></span> 
                            <span className="name-Item">Settings</span>
                        </Link>
                    </div>
                </li>
                    )
                }
              
            </ul>
        </div>
    </div>
  )
}

export default Sidebar