import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";


function DlgClassificationCat({showClassificationCats, setShowClassificationCats, catid, catalogue, person} ) {

    const [tokenString, setTokenString]                          = useState("");
    const [listClassificationCats, setListClassificationCats]    = useState([]);

    const [processing, setProcessing]                            = useState(false);
    const [searchWord, setSearchWord]                            = useState([]);
    const [newWord, setNewWord]                                  = useState('');
    const [messageAdvanced, setMessAdvanced]                     = useState(); 

    const classificationRef                                      = useRef();
    const advancedSelectCategoryRef                              = useRef();


   useEffect(()=> {    
      if((catalogue !== undefined) && (catalogue !== null) && (catalogue.length > 0)){

        fetchCurrentCategories();  
        fetchCurrentToken();
      }          

    },[catalogue]);

 

    const fetchCurrentCategories = async () => {

        try{

            setListClassificationCats(catalogue.classification);            

        }catch(erClassify){
            console.log(erClassify);
        }
    }
 
    const fetchCurrentToken = async () => {

        try{

            var catId = catalogue._id;

            var config = {
                method: 'get',
                url: CONSTANTS.API_URL + 'tokens/catalogue/'+ catId,
                    headers: {
                    'token': 'Bearer '+ person.accessToken,
                    'Content-Type': 'application/json'
                    }

              };

            const resToken = await axios(config);

            setTokenString(resToken.data[0].token);

           

        }catch(erToken){
            console.log(erToken);
            console.log("Error Fetching Token ");
            console.log(" |||||||||||||||||||| ");

        }

    }

    async function handleCatSavingClassification(e) {

        e.preventDefault();  
        setProcessing(true);  

        try{           

                ///////////////////******** FORM WORK */

                const categoryClassification =  classificationRef.current.value;

                const catalogItem = {
                    "catalogueid": catalogue._id,
                    "categoryname": categoryClassification
                }


                const response = await axios.post(CONSTANTS.API_URL+"catalogues/addcategory/classification", catalogItem, {
                    headers: {
                        token: "Bearer "+ person.accessToken
                    }

                });

               setListClassificationCats(response.data.classification);
               classificationRef.current.value = "";

         

            setProcessing(false);

        } catch (err){
          console.log("Something went wrong ");
          console.log(err);
          setProcessing(false);
        }      
    }

    const deleteCurrentCategory = async (catItem, index)  =>  { 

        try{

            const catalogItem = {
                "catalogueid": catalogue._id,
                "categoryname": catItem

            }


            const response = await axios.put(CONSTANTS.API_URL+"catalogues/removecategory/classification", catalogItem, {
                headers: {
                    token: "Bearer "+ person.accessToken
                }

            });

            setListClassificationCats(response.data.classification);

        }catch(err){

            console.log(err);

        }

      }

 

      const handleAddSearchWord = () => {

        setSearchWord([...searchWord, newWord]);

        setNewWord(''); // Clear input field

      };

 

      const handleRemoveWord = (index) => {
        setSearchWord(searchWord.filter((todo, i) => i !== index));
      }; 

      async function handleSelectedCategory() {

         const advancedSelect   = advancedSelectCategoryRef.current.value;

         /////////////////

        if((searchWord.length > 0) && (advancedSelect.length > 0)){

           // console.log(searchWord);

            setMessAdvanced("");

            setProcessing(true);  

            try{
                 

                let dataAssigner = JSON.stringify({
                    "category": advancedSelect,
                    "searchwords": searchWord,
                    "catalogueid" : catalogue._id
                });

 
                var config = {
                    method: 'put',
                    maxBodyLength: Infinity,
                    url: CONSTANTS.API_URL + 'catalogues/classification/category/assigning',
                    headers: {
                        'api_key' : tokenString,
                        'Content-Type': 'application/json'
                    },
                    data : dataAssigner

                  };

                //console.log(config);

                const responseAss = await axios(config);

                setProcessing(false);  
           

            }catch(err){

                console.log(err);

                setProcessing(false);  

            }

        }else {

            setMessAdvanced("Please ensure that search words are added and select a category.");

        }

         /////////////////

      }

 
  return (
    <div className="block-comp">    

         <Dialog header="Classification" visible={showClassificationCats} onHide={() => setShowClassificationCats(false)} style={{ width: '50vw' }}
            footer={
            <div>
                <Button className="btn btn-picky" label="Close" onClick={() => setShowClassificationCats(false)} />
            </div>
            }>
            <div className="modal-body-content">
                <div className="tok-info">
                    <div className="list-modal-items">
                            <h3>Classification of Categories</h3>
                            <div className="flexme">
                                <div className="spaceshare2">
                                   <div className="list-cat pd5 classify-scroll">
                                       {
                                           listClassificationCats && (  
                                           <ul className="no-bullet cat-box">

                                                {

                                                    listClassificationCats.map((catitem, i) => {

                                                    return (

                                                    <li key={catitem._id} className="ln-item">

                                                        <div className="flexme">

                                                            <div className="block-wide-a">

                                                                <div className="title-up"><span>{1 + i}</span> {catitem}</div>                                                              

                                                            </div>
                                                            <div className="block-b">                                                          
                                                                <button className="btn btn-blue btsm1" onClick={() => deleteCurrentCategory(catitem, i)}>X</button>
                                                            </div>
                                                        </div>                                                   
                                                    </li>

                                                    )
                                                })
                                              }
                                           </ul>
                                           )
                                        }
                                   </div>
                                </div> 

                                <div className="spaceshare2">
                                   <div className="adding-cat pd10">
                                     <form onSubmit={handleCatSavingClassification}>
                                        <div className="form-group">

                                            <div className="lbldesc">Add the Category*: </div>

                                            <input type="text" ref={classificationRef} className="form-control w80" required/>
                                        </div>

                                        <div className="form-group">
                                            <button type="submit" className="btn btn-picky" disabled={processing}>Create</button>
                                        </div>

                                            {

                                                processing && (
                                                    <div className="img-source">
                                                       <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                                                    </div>
                                                )

                                            }

                                      </form>                                    

                                   </div>
                                </div>
                            </div>    
                    </div>
                </div>
                {

                    listClassificationCats && (
                        <div className="tok-info">
                            <div className="list-modal-items">
                                <h4>Advanced Assignment of Categories</h4>
                                <div className="detail-adv">
                                    {

                                        messageAdvanced && (

                                            <div className="alert alert-danger">

                                                {messageAdvanced}

                                            </div>

                                        )

                                    }

                                  <select className="form-control wfull" ref={advancedSelectCategoryRef}>

                                        <option className="opt" value=""></option>

                                        {

                                           listClassificationCats.map((category, index) => (

                                            <option key={index} value={category}>

                                                 {category}

                                            </option>

                                            ))

                                        }                                        

                                    </select>

                                    <div className="listing-search-words">
                                        <ul className="listings-side">
                                            {searchWord.map((todo, index) => (
                                            <li key={index}>
                                                {todo}
                                                <button className="btn btn-danger btsm1" onClick={() => handleRemoveWord(index)}>X</button>

                                            </li>

                                            ))}
                                        </ul>
                                    </div>
                                    <div className="hlabel">
                                       Add word to search the list with in order to place the selected category in the found products
                                    </div>
                                    <input
                                        placeholder="Add to search the list with"
                                        type="text"
                                        value={newWord}
                                        className="form-control"
                                        onChange={(e) => setNewWord(e.target.value)}

                                    />

 

                                   <div className="bt-details">

                                        <button onClick={handleAddSearchWord} className="btn btn-blue ">Add Word</button>

                                        <button onClick={handleSelectedCategory} className="btn btn-blacky mglt10">

                                            Update Categories

                                        </button>

                                    </div>

                                </div>

                            </div>

                        </div>

                    )

                }                

            </div>
        </Dialog>
    </div>
  )
}

export default DlgClassificationCat