import React, { useContext, useRef, useState, useEffect } from 'react'
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import DlgSuppUsers from '../components/DlgSuppUsers';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { Link, useNavigate } from 'react-router-dom';
import DlgSupplierApprovalCode from '../components/DlgSupplierApprovalCode';

function SupplierDetails() {

  const { user }                                = useContext(AuthContext);
  const [processing, setProcessing]             = useState(false);

  const params                                       = useParams();

  const [imagepreview, setImagePreview]              = useState(CONSTANTS.PREVIEW_IMAGE);
  const [addMsg, setAddMsg]                          = useState("");
  const [msgClass, setMsgClass]                      = useState("");
  const [addImgMsg, setAddImgMsg]                    = useState("");
  const [showSubmition, setShowSubmission]           = useState(true);
  const [prodList, setProdList]                      = useState([]);
  const [showApprovalCode, setShowApprovalCode]      = useState(false);

  const [currentSupplier, setCurrentSupplier]                     = useState({});
  const [members, setMembers]                                     = useState([]);
  const [memeberIds, setMemberIds]                                = useState([]);
  const [showDialogueSuppliers, setShowDialogueSuppliers]         = useState(false);
  const [presentList, setPresentList]                             = useState([]);
  const [selectedCat, setSelectedCat]           = useState(null);
  const [selectedCatOb, setSelectedCatOb]       = useState({});

  const [userSetup, setUserSetup]                                 = useState(0);
  const [updateMe, setUpdateMe]                                   = useState(0);
  const [photoUpdate, setPhotoUpdate]                             = useState(0);
  const [approveSecCode, setApproveSecCode]                       = useState(0);

  const [categoryList, setCategoryList]                           = useState([]);

  const barcodeRef                      = useRef();
  const titleRef                        = useRef();
  const onlinedescRef                   = useRef();
  const descriptionRef                  = useRef();
  const sizeRef                         = useRef();
  const brandRef                        = useRef();
  const presentationRef                 = useRef();
  const unitCheckRef                    = useRef();
  const costPriceRef                    = useRef();
  const priceRef                        = useRef();
  const mainImageRef                    = useRef();

  const navigate                        = useNavigate();

  useEffect(() => {
    getSupplierPictures();    
  },[photoUpdate]);

  useEffect(() => { 
      if(memeberIds){    
        performUserFetch();
      
      }
      //console.log("(==========================)");
  },[ userSetup]);
  useEffect(() => {
    getCurrentSupplier();
    getListOfPresentations();
    getListOfCategories();
  },[]);

  const performUserFetch = async () => {
    setProcessing(true);
     
      try{
       
          const repUsers = await axios.get(CONSTANTS.API_URL+"suppliers/details/"+ params.id, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });
          //console.log("alaska");
          //console.log(repUsers.data[0].userIds);
           setMemberIds(repUsers.data[0].userIds);
           

           if(repUsers.data[0].userIds.length > 0 ){

                const stringy = JSON.stringify(repUsers.data[0].userIds);
                //console.log("Search here");
                //console.log(stringy);
    
              const res = await axios.get(CONSTANTS.API_URL + "suppliers/getsuppliers?userssearch=" + stringy, {
                headers: {
                  token: "Bearer " + user.accessToken
                }
              });
              //console.log("piiiing")
              //console.log(res.data.Users);
              setMembers(res.data.Users);
             
           }

          setUpdateMe(updateMe + 1);
          setProcessing(false);
      } catch(err){
          console.log(err);
          setProcessing(false);
      }
      
  }
  const addSuppToListedUsers = async (member) => {
        //console.log("Parent");
        setProcessing(true);

        const memberId = member._id;
              // const newMemberIds = supplier.userIds.concat(memberId);
              // console.log("China");
    //    console.log(memberId);
            // const catId = supplier._id;
            // console.log("hello" + newMemberIds);

        try{
      
        const allMemberIds = [];
        allMemberIds.push(memberId);

        const idListMem = JSON.stringify(allMemberIds);
          var config = {
            method: 'post',
            url: CONSTANTS.API_URL + 'suppliers/addcheck?srcfcat='+params.id,
            headers: { 
              'token': 'Bearer ' + user.accessToken,
              'Content-Type': 'application/json'
            },
            data : {
              "userIds": idListMem
            }
          };
          const respo = await axios(config);
         
          //console.log("pancakey");
          //console.log(respo.data);
          setUserSetup(userSetup + 1);
          setMemberIds(allMemberIds);
          setProcessing(false);
      }catch(erUser){
          console.log("Dialogue Error Supplier");
          console.log(erUser);
          setProcessing(false);
      }
  }

  const removeSuppToListUsers = async (member) =>{
    setProcessing(true); 
    const memberId = member._id;
    //console.log("Remove id  :" + memberId);
    const allMemberIds = memeberIds;
    //console.log(allMemberIds);
    const indexForDel = allMemberIds.indexOf(memberId);
    //console.log(" INDEX " + indexForDel);
    allMemberIds.splice(indexForDel, 1);
   
    try {

  
      var config = {
        method: 'delete',
        url: CONSTANTS.API_URL + 'suppliers/removeuser?srcdcat='+params.id,
        headers: { 
          'token': 'Bearer ' + user.accessToken,
          'Content-Type': 'application/json'
        },
        data : {
          "userIds": allMemberIds
        }
      };
      const res = await axios(config);
      //console.log(res.data);
      //console.log("Re deletele");
      setMemberIds(allMemberIds);
     //console.log(memeberIds);
      setUserSetup(userSetup - 1);
      setProcessing(false);
     

    }catch(err){
      setProcessing(false);
      console.log("Delete user from Catalogue Array Err");
      console.log(err);
    }

  }

  const getCurrentSupplier = async () => {
    try{
      const supSingle = await axios.get(CONSTANTS.API_URL+"suppliers/single/"+ params.id, {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });
        /*
        console.log("&&&&&&&&&&&&&&&&&&&&&&&");
        console.log(supSingle.data);
        console.log(supSingle.data.currentSupplier);
        */
        setCurrentSupplier(supSingle.data.currentSupplier);
        setApproveSecCode(supSingle.data.currentSupplier.approvalCode)

    }catch(er){
      console.log(er)
    }
  }

  async function handleFormUploadPhoto(e) {
    e.preventDefault();  
    
    setProcessing(true);
    try {
      //Title copies entered
      if(titleRef.current.value.length > 1  && barcodeRef.current.value > 4){
        
        //if image is not empty
        if(mainImageRef.current.files[0]){
         
          setAddMsg("");
          setMsgClass("");
          //console.log(params.id);
          //console.log(selectedCatOb);
            //Check Category first    
            if(Object.keys(selectedCatOb).length === 0){             
              //console.log("Category Has Not been selected");
              setAddMsg("Please select a Category");
              setMsgClass("alert-danger");
            } else {             

              //console.log("Category been selected, so we go ahead.");
              setAddMsg("");
              setMsgClass("");
              const formData = new FormData();

              formData.append("imgurl", mainImageRef.current.files[0]);
              formData.append("barcode", barcodeRef.current.value);
              formData.append("title", titleRef.current.value);
              formData.append("onlineDesc", onlinedescRef.current.value);
              formData.append("description", descriptionRef.current.value); 
              formData.append("brand", brandRef.current.value);  
              formData.append("size", sizeRef.current.value);
              formData.append("unit", unitCheckRef.current.value);  
              formData.append("presentation", presentationRef.current.value);  
              formData.append("cost_price", costPriceRef.current.value); 
              formData.append("price", priceRef.current.value); 
              formData.append("supplierid", params.id);    
              formData.append("categoryID", selectedCatOb.catId);    
              formData.append("catName", selectedCatOb.categoryName);    
              formData.append("catDescription", selectedCatOb.categoryDescription);    
              formData.append("catImage", "");    
              formData.append("draftmode", false);    
            
              const response = await axios.post(CONSTANTS.API_URL+"pictures/upload", formData, {
                  headers: {
                      token: "Bearer "+ user.accessToken
                  }
              });
              
              //console.log(response.data);
              if(response.data.status === "success"){
                setAddMsg("Image added successfully.");
                setMsgClass("alert-success");
         
                setPhotoUpdate(photoUpdate + 1);

                  //===///////////////////////===
                  let randOmNumb = Math.floor((Math.random() * 1000) + 1);
                    //Send to Lets Trade
                    var tradeCons = {
                      "image_url" : response.data.savedData.imgurl,                                                     
                      "title" : titleRef.current.value,
                      "sub_title" : onlinedescRef.current.value,
                      "description" : descriptionRef.current.value,
                      "brand" : brandRef.current.value,
                      "manufacturer":"",
                      "sku": barcodeRef.current.value,
                      "pid":  randOmNumb + "",
                      "quantity_type" : presentationRef.current.value,
                      "quantity" : sizeRef.current.value,
                      "cost_price" : costPriceRef.current.value + "",
                      "price" : priceRef.current.value + "",
                      "on_promotion" : false,
                      "promotion_price" : "",
                      "variations" : null,
                      "category" : {
                          "id": selectedCatOb.catId,
                          "name": selectedCatOb.name || selectedCatOb.categoryName,
                          "description": selectedCatOb.description || selectedCatOb.categoryDescription,
                          "image_url": "",
                      },
                      "barcode" : barcodeRef.current.value,   
                      "notes" : "",
                      "extra_fields" : [],                           
                      "status": "AVAILABLE",
                      "add_ons": null

                  }

                  //console.log(tradeCons);
                  var dataTrade = JSON.stringify(tradeCons);
                  //console.log("$$$$$$------$$$$$$-----$$$$$$");

                  //console.log("Should Only have strings");
                  //console.log(dataTrade);
                  var config = {
                    method: 'post',
                    url: CONSTANTS.LETSTRADE1 + "product/add_product",
                    headers: { 
                        //Authorization: CONSTANTS.LTTOKEN
                        'Authorization': 'Bearer ' + CONSTANTS.LTTOKEN,
                        'Content-Type': 'application/json'
                    },
                    data : dataTrade
                  };
                  const letsTradeAdd = await axios(config);
                  console.log("LETS TRADE    LETS TRADE    LETS TRADE     LETS TRADE ");
                  console.log(letsTradeAdd.data);
                  e.target.reset();
                //===///////////////////////
              }else {
                  //console.log("Server blocked it");
                  setAddMsg(response.data.message);
                  setMsgClass("alert-danger");
              }
            
            }
             
          setProcessing(false);
              
        }else{
          setAddMsg("Image has not been added ");
          setMsgClass("alert-danger");
          setProcessing(false);
        }

      }else {
          setAddMsg("Please enter valid data on required fields. (Title and barcode) ");
          setMsgClass("alert-danger");
          setProcessing(false);
      }

    }catch(err){
      console.log("UPLOAD Error");
      console.log(err);
      setAddMsg("Something went wrong. Please try again later. ");
      setMsgClass("alert-danger");
      setProcessing(false);
    }

  }

  const checkMainImage  = () => {
    //console.log("Selection");
    if(mainImageRef.current.files[0]){
          //get photo size
          var totalBytes   = mainImageRef.current.files[0].size;
          //convert photo to mb
          var totMb        = totalBytes/1000000;
          //round off
          var totMbRounded = Math.round(totMb * 10) / 10;
           //console.log(mainImageRef.current.files[0]);
           //console.log( totMbRounded );

           if(totMbRounded > 1.5){
             setAddImgMsg("Sorry image too large at " + totMbRounded + " mb. Image should be less than 1.5");
             setShowSubmission(false);
           }else {
            //if the picture is sound then check if its name is a barcode
            const fileName = mainImageRef.current.files[0].name.replace(/\.[^/.]+$/, "");
            //console.log("Name: "+ fileName );
              if(isNumber(fileName)){
                  //now check if the barcode and filename are the same we can go forward
                  if(fileName === barcodeRef.current.value){
                    setImagePreview(URL.createObjectURL(mainImageRef.current.files[0]));
                    setAddImgMsg("");
                    setShowSubmission(true);
                  }else {
                    setAddImgMsg("The barcode and title of the picture are not the same. ( "+ barcodeRef.current.value +" OR "+fileName+ " )");
                     setShowSubmission(false);
                  }                 

                } else {

                  setAddImgMsg("Please ensure that the title of the is the barcode with an extension. Example barcode 190000001 and image 190000001.png");
                  setShowSubmission(false);
                }
           }
          
      }else{
        //console.log("Empty Image");
        setImagePreview(CONSTANTS.PREVIEW_IMAGE)
      }
  }
  function isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); } 

  const executeCheckMain = (e) => {
    e.preventDefault();
    //console.log("Impact");
    mainImageRef.current.click();
  }

  const getSupplierPictures = async () => {
      try{

        const respPics = await axios.get(CONSTANTS.API_URL+"suppliers/summon/"+ params.id, {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });

       // console.log("19191919119191");
       // console.log(respPics.data);
        setProdList(respPics.data.Pictures);

      }catch(error){
        console.log("Get Supply Pics");
        console.log(error);
      }
  }

  const deleteSupplier = async () => {
    setProcessing(true);

    try {
      const res = await axios.delete(CONSTANTS.API_URL + "suppliers/terminate/" + params.id ,{
        headers: {
          token: "Bearer " + user.accessToken
        }
      });
      //console.log(res.data);
      //console.log("Re deletele");
      setProcessing(false);
      navigate("/suppliers", { replace: true });

    }catch(err){
      setProcessing(false);
      console.log("Terminate Err");
      console.log(err);
    }
    
  }

  const getListOfPresentations = async () => {
      try{
          const response = await axios.get(CONSTANTS.API_URL+"pictures/presentations", {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });

          //console.log(response.data);
          setPresentList(response.data);
          //console.log("*********")
      }catch(errs){
      
          console.log(errs);
      }
  } 

  const getListOfCategories = async () => {

      try{
          const response = await axios.get(CONSTANTS.API_URL+"categories/list", {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });

          //console.log(response.data.data);
          setCategoryList(response.data.data);
          //console.log("######*********")
      }catch(errs){
          console.log(errs);
      }
  }
  const updateSelectedCategory = async (catValue, catObject) => {
      setSelectedCat(catValue);
      setSelectedCatOb(catObject);
  }
  return (
    <div className="main-standard">
       <h2 className="lbl-title-page">Suppliers Details</h2>
       <DlgSuppUsers showDialogueSuppliers={showDialogueSuppliers} setShowDialogueSuppliers={setShowDialogueSuppliers} addSuppToListedUsers={addSuppToListedUsers} removeSuppToListUsers={removeSuppToListUsers} members={members} memeberIds={memeberIds} />
       <div className="section-box form-section">
        <div className="card">
        <div className="but-small pd10 mt-3 lg:mt-0">
          {
            user.isAdmin && (
              <Button
              label="View Supplier List"
              className="btn btn-picky btsm1 mr-2"
              onClick={() => setShowDialogueSuppliers(true)}
            />
            )
          }

          {currentSupplier &&(
            <h3>{currentSupplier.title}</h3>
          )}
          <Button
            label="Approval Code"
            className="btn btn-picky btsm1 mr-2"
            onClick={() => setShowApprovalCode(true)}
          />
          <DlgSupplierApprovalCode 
              showApprovalCode={showApprovalCode}   
              setShowApprovalCode={setShowApprovalCode}
              appcode={approveSecCode}
              setApprovalCode={setApproveSecCode}
              supplierId={currentSupplier._id}
              members={user}
              />
         </div>
            <div className="card-body">
                <h4>Add Image</h4>

                <form onSubmit={handleFormUploadPhoto}>
                  <div className="row">
                     <div className="col-md-6">
                         <div className="sp-group">
                            <div className="form-group">
                                <div className="lbldesc">Barcode*: </div>
                                <input type="number" ref={barcodeRef} className="form-control w80" required/>
                            </div>
                         </div>
                         <div className="sp-group">
                            <div className="form-group">
                                <div className="lbldesc">Title*: </div>
                                <input type="text" ref={titleRef} className="form-control w80" required/>
                            </div>
                         </div>
                        
                         <div className="sp-group">
                            <div className="form-group">
                                <div className="lbldesc">Online Description: </div>
                                <textarea className="form-control w80 " ref={onlinedescRef} ></textarea>
                            </div>
                         </div>
                         <div className="sp-group">
                            <div className="form-group">
                                <div className="lbldesc">Description: </div>
                                <textarea className="form-control w80 htbigger " ref={descriptionRef}></textarea>
                            </div>
                         </div>

                         <div className="sp-group">
                             <div className="form-group">
                                <div className="lbldesc">Brand*: </div>
                                <input type="text" ref={brandRef} className="form-control w80" required/>
                             </div>
                         </div>
                         <div className="sp-group">
                            <div className="form-group">
                                <div className="lbldesc">Presentation: </div>                                   
                                    {presentList && (
                                        <select className="form-control w80" ref={presentationRef}>
                                            {presentList.map((prese, i) => {
                                                return (<option key={i} className="opt" value={prese.title}>{prese.title}</option>)
                                            })}
                                        </select>
                                    )}
                                      
                            </div>
                         </div>
                         <div className="sp-group">
                              <div className="form-group">
                                  <div className="row rws-form-g">
                                      <div className="col-md-6">
                                              <div className="f-group">
                                                  <div className="lbldesc">Size: </div>
                                                  <input type="text" ref={sizeRef} className="form-control wfull" />
                                              </div>
                                          </div>
                                          <div className="col-md-6">
                                              <div className="f-group">
                                                  <div className="lbldesc">Unit Check: </div>
                                                  <select className="form-control wfull" ref={unitCheckRef}>
                                                      <option className="opt" value=""></option>
                                                      <option className="opt" value="g">g</option>
                                                      <option className="opt" value="mg">mg</option>
                                                      <option className="opt" value="ml">ml</option>
                                                      <option className="opt" value="mcg">mcg</option>
                                                      <option className="opt" value="L">L</option>
                                                      <option className="opt" value="µg">µg</option>                                                   
                                                  </select>
                                              </div>
                                          </div>
                                  </div>
                              </div>
                         </div>
                         <div className="sp-group">
                              <div className="form-group">
                                <div className="row rws-form-g">
                                      <div className="col-md-6">
                                              <div className="f-group">
                                                  <div className="lbldesc">Cost Price: </div>
                                                  <input type="text" ref={costPriceRef} className="form-control wfull" />
                                              </div>
                                          </div>
                                          <div className="col-md-6">
                                              <div className="f-group">
                                                  <div className="lbldesc">Price: </div>
                                                  <input type="text" ref={priceRef} className="form-control wfull" />
                                              </div>
                                          </div>
                                </div>
                              </div>
                         </div>
                     </div>
                     <div className="col-md-6">
                         
                     <div className="lbldesc">Category*: </div>
                                           <div className="category-box ctbox1 catshortn">
                                                {
                                                    categoryList && (
                                                        <div className="cat-boxer add-photo-rad">
                                                            {
                                                                categoryList.map((catitem, indi) => {
                                                                    return (
                                                                        <div className="main-cat-item" key={catitem._id}>
                                                                            <div className="field-radiobutton main-cat" >
                                                                                    <RadioButton inputId={"catego" + indi} name="catego" value={catitem.categoryName} onChange={(e) => updateSelectedCategory(e.value, catitem)} checked={selectedCat === catitem.categoryName} />
                                                                                    <label htmlFor={"catego" +indi}>{catitem.categoryName}</label>  
                                                                            </div>
                                                                            {(catitem.sub_categories.length > 0) && (
                                                                                        <div className="sub-cat-container"  >
                                                                                            {
                                                                                            catitem.sub_categories.map((catSub, i) => {
                                                                                                return (
                                                                                                <div className="sub-cat" key={catSub.catId} >
                                                                                                
                                                                                                    <RadioButton inputId={"subcatego" + ""+ indi +""+i} name="subcatego" value={catSub.name} onChange={(e) => updateSelectedCategory(e.value, catSub)} checked={selectedCat === catSub.name} />
                                                                                                    <label htmlFor={"subcatego" + ""+ indi +""+i}>{catSub.name}</label>
                                                                                                </div>
                                                                                                )
                                                                                            })
                                                                                            }
                                                                                        </div>
                                                                                    )}
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                }
                                           </div>
                        
                     </div>
                     <div className="col-md-6">
                          <div className="sp-group ">
                                <div className="form-group">
                                    <div className="lbldesc">Select main image to upload* : </div>
                                    <input type="file" className="form-control opcifileinput" onChange={() => checkMainImage()} ref={mainImageRef}  accept="image/*" />
                                </div>
                                <div className="form-group">
                                   <button className="btn-nostyle" onClick={executeCheckMain}><div className="importclick"><FontAwesomeIcon icon={faUpload} /></div></button>
                                    <img className="previewItem" src={imagepreview} alt="PickNick Preview image" />
                                </div>
                          </div>
                     </div>
                     <div className="col-md-6">
                     
                          <div className="sp-group mgtop90">
                                <p>Please save the title of the image as a barcode <span className="italisizer">190000001.jpg or 190000001.png</span></p>
                                <p>Please  ensure that a square image is uploaded. Recommended dimmensions are <strong>1000 x 1000</strong></p>
                                <img src={CONSTANTS.IMAGE_PREV_SIZE} className="sizeex" />
                                <p>Please  ensure that the image size is less than  <strong>1.5</strong> mb</p>

                                {
                                  addImgMsg && (
                                    <div className="alert-area alert-danger">
                                        {addImgMsg}
                                    </div>
                                  )
                                }
                          </div>
                     </div>
                     <div className="col-md-6">
                          <div className="sp-group">
                            {showSubmition && (
                                  <button type="submit" className="btn btn-picky">Add Image</button>
                              )
                            }
                                 
                          </div>
                      </div>
                  </div>
                </form>

                {
                        processing && (
                            <div className="img-source">
                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc profixed" />
                            </div>
                        )
                     }
                     {
                        addMsg && (
                            <div className={"alert-area " + msgClass}>
                               {addMsg}
                            </div>
                        )
                      }
            </div>
        </div>
       </div>
       <div className="section-box list-section mgtop30">
           {prodList  && 
              (
                <div className="card">
                  <div className="card-body">
                      <div className="row">

                      {
                        prodList.map((pro) => {
                          return <div className="col-md-3" key={pro._id}>
                                    <div className="pr-image">
                                        <Link to={"/photo/" + pro._id} className="lk-dash">
                                          {pro.barcode}
                                        </Link>
                                        <div className="">
                                          <Link to={"/photo/" + pro._id} className="lk-dash">
                                            <img src={pro.imgurl} className="img-responsive fl-image" />
                                          </Link>
                                        </div>
                                    </div>
                                </div>
                        })
                      }
                      </div>
                  </div>
                </div>
              )
           }
       </div>

       {
                user.isAdmin && (
                  <div className="bottom-foot">
                      <Button
                              className="btn btn-picky mr-2"
                              label="Delete Supplier"
                              onClick={deleteSupplier}
                            />
                    </div>
                )
              }
    </div>
  )
}

export default SupplierDetails