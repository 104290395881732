import Sidebar from "./components/Side/Sidebar";
import Tobnavbar from "./components/Top/Tobnavbar";
import Home from "./pages/Home";
import AddPhotos from "./pages/AddPhotos";
import UserList from "./pages/UserList";
import Photo from "./pages/Photo";
import Login from "./pages/Login";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PhotoList from "./pages/PhotoList";
import MediaList from "./pages/MediaList";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import Catalogue from "./pages/Catalogue";
import CatalogueAdd from "./pages/CatalogueAdd";
import FileImports from "./pages/FileImports";
import CatalogueDetails from "./pages/CatalogueDetails";
import TokenList from "./pages/TokenList";
import Settings from "./pages/Settings";
import AccessDenied from "./pages/AccessDenied";
import SupplierDetails from "./pages/SupplierDetails";
import SupplierAdd from "./pages/SupplierAdd";
import Suppliers from "./pages/Suppliers";
import Pipeline from "./pages/Pipeline";
import SalesLayer from "./pages/SalesLayer";
import Categories from "./pages/Categories";
import ForgotPassword from "./pages/ForgotPassword";
import KnowledgeBase from "./pages/KnowledgeBase";

function App() {
  const { user } = useContext(AuthContext);
  //console.log(user);
  console.log("DARK SIDE");
  console.log(user);
  
  return (
    <Router>
   { user ? (
     <div className="dashingboard-outer">      
        <div className="dashingboard">
            <div className="side-dash">
              <Sidebar member={user} />
            </div>
            <div className="main-dash-content">
              <div className="top-nav">
                  <Tobnavbar />
              </div>
              <div className="main-content-arena">
              <Routes>
                  <>
                      <Route exact path="/" element={<Home />} />
                      <Route path="/users" element={<UserList />} />
                      <Route path="/addphotos" element={<AddPhotos />} />
                      <Route path="/library" element={<PhotoList />} />
                      <Route path="/media" element={<MediaList />} />
                      <Route path="/photo/:id" element={<Photo />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/imports" element={<FileImports />} />
                      <Route path="/catalogue" element={<Catalogue />} />
                      <Route path="/add-catalogue" element={<CatalogueAdd />} />
                      <Route path="/catalogue-details/:id" element={<CatalogueDetails />} />
                      <Route path="/suppliers" element={<Suppliers />} />
                      <Route path="/add-supplier" element={<SupplierAdd />} />
                      <Route path="/supplier-details/:id" element={<SupplierDetails />} />
                      <Route path="/tokens" element={<TokenList />} />
                      <Route path="/knowledge-base" element={<KnowledgeBase/>} />
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/access-denied" element={<AccessDenied />} />
                      <Route path="/pipeline" element={<Pipeline />} />
                      <Route path="/saleslayer" element={<SalesLayer />} />
                      <Route path="/categories" element={<Categories/>} />
                  </>
              </Routes>
              </div>
            </div>
          </div>

      </div>
   )
   :
     <Routes>
       <Route exact path="/" element={user ? <Home /> : <Navigate to="/login"/> }/>
       <Route path="/login" element={!user ? <Login /> : <Navigate to="/"/>}/>  
       <Route path="/sign-up" element={<SignUp />} />     
       <Route path="/forgot-password" element={<ForgotPassword />} />       
     </Routes>
     }
 </Router>
  );
}

export default App;
