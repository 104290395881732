import React from 'react';
import ReactDOM from 'react-dom/client';
// import prime react
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import './index.css';
import './inresponsive.css';
import App from './App';
import {AuthContextProvider} from "./context/AuthContext";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <AuthContextProvider>
       <App />
     </AuthContextProvider>    
  </React.StrictMode>
);
