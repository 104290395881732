import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faListAlt } from '@fortawesome/free-solid-svg-icons';

function TotalCategories() {

    const [total, setTotal] = useState(0); 

    useEffect(() => {

        getTheTotalCategories();

    },[]);

 

    const getTheTotalCategories = async () => {

        try{

            const response = await axios.get(CONSTANTS.API_URL + "categories/totalcategories");
            setTotal(response.data.total)

        }catch(err){

            console.log("Err Totals Categorie");
            console.log(err);
        }
    }

  return (
    <div className="col-md-3">
        <div className="card flex-mg-space ">
            <div className="card-body widget-body">
                <div className="home-dash-item">
                    <div className="ico-title ico-tt-blue">
                       <FontAwesomeIcon icon={faListAlt} />
                    </div>

                    <h3 className="hdi-title">Total Categories</h3>
                    <div className="numFeed">
                        {total}
                    </div>
                </div>
            </div>
        </div>
    </div>

  )

}

 

export default TotalCategories