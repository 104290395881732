import React, { useRef, useState  } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';


function DlgEditPhoto({setShowDialPhoto, showDialPhoto, setSelectedPhotoInfo, selectedPhotoInfo, supplierName, personAdmin}) {

    const barcodeRef                             = useRef();
    const titleRef                               = useRef();
    const onlineDescRef                          = useRef();
    const descriptRef                            = useRef();
    const brandRef                               = useRef();
    const sizeRef                                = useRef();
    const unitRef                                = useRef();
    const presentationRef                        = useRef();
    const costpriceRef                           = useRef();
    const priceRef                               = useRef();
    const draftmodeRef                           = useRef();

    const [processing, setProcessing]                            = useState(false);

    const [msgFeedback, setMsgFeedback]                          = useState("");
    const [msgClass, setMsgClass]                                = useState("");

    const [sideMsgeFeed, setSideMsgFeed]                         = useState("");
    const [sMsgClass, setSMsgClass]                              = useState("");
    const [unitOptions, setUnitOptions]                          = useState(["g", "mg", "ml", "mcg", "L","µg"]);



    const deleteThisField = async () => {
       // console.log("Delete this guy");
       // console.log(selectedPhotoInfo);

        try{

            setMsgFeedback("");
            setMsgClass("");


               const response = await axios.delete(CONSTANTS.API_URL+"pictures/terminate/" + selectedPhotoInfo._id, {
                    headers: {
                        token: "Bearer "+ personAdmin.accessToken
                    }
                });
               //console.log(response.data);
               setMsgFeedback(response.data);
               setMsgClass("alert-warning");
               setProcessing(false);


              setSelectedPhotoInfo([]);
              //LETSTRADE DELETE START
              const resPicsTradeBarcode = await axios.get(CONSTANTS.LETSTRADE1+'product/products?page=1&barcode=' + selectedPhotoInfo.barcode, {
                        headers: {
                            'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN,
                        }
                    });

                       //UPDATE PRODUCT
                       if(resPicsTradeBarcode.data.success === true){
                        var product_id = resPicsTradeBarcode.data.content.product_list[0]._id;

                        var dataDel = JSON.stringify({"product_id": product_id});
                        var configDel = {
                            method: 'put',
                            url: CONSTANTS.LETSTRADE1 + 'product/delete_product',
                                headers: {
                                    'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN,
                                    'Content-Type': 'application/json'
                                },
                             data : dataDel
                            };
                            let execProd = await axios(configDel);
                       }
              //LETSTRADE DELETE END

        }catch(err){
            setMsgFeedback("Something went wrong, please try again later");
            setMsgClass("alert-danger");
            setProcessing(false);
            //console.log(err);
        }
    }

    async function handleFormUpdatePhoto(e) {
        e.preventDefault();
        setProcessing(true);

        try{

            setMsgFeedback("");
            setMsgClass("");
            let draft = false;
            let availa = "NOT AVAILABLE";

            if(draftmodeRef.current.value === "true"){
                draft = true;
                //CHECK THE ACKEMENSHOW FIELD FIRST
                availa = "AVAILABLE";
            }

            const userOb = {
                "title": titleRef.current.value,
                "onlineDesc" : onlineDescRef.current.value,
                "description" : descriptRef.current.value,
                "brand" : brandRef.current.value,
                "size" : sizeRef.current.value,
                "unit" : unitRef.current.value,
                "cost_price" : costpriceRef.current.value,
                "price" : priceRef.current.value,
                "presentation" : presentationRef.current.value,
                "draftmode" : draft,
                "photoid" : selectedPhotoInfo._id
               }

               //console.log(userOb);

               const response = await axios.put(CONSTANTS.API_URL+"pictures/updatepicture", userOb, {
                    headers: {
                        token: "Bearer "+ personAdmin.accessToken
                    }
                });
               //console.log(response.data);

               setMsgFeedback("Picture updates have been updated.");
               setMsgClass("alert-success");

               //setSelectedPhotoInfo(response.data);
               //UPDATE THIS COPY ON LETS TRADE AS WELL
               //LETSTRADE START
               //GET OBJECT USING BARCODE
               const resPicsTradeBarcode = await axios.get(CONSTANTS.LETSTRADE1+'product/products?page=1&barcode=' + selectedPhotoInfo.barcode, {
                    headers: {
                        'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN,
                    }
                });

                console.log("GET OBJECT USING BARCODE");
                console.log(resPicsTradeBarcode);
                console.log(resPicsTradeBarcode.data);

                //UPDATE PRODUCT
                if(resPicsTradeBarcode.data.success === true){
                    console.log("UPDATE PRODUCT");
                    var product_id = resPicsTradeBarcode.data.content.product_list[0]._id;
                    //console.log(" -  -  -  -  -  -  -  - " );
                    var tradeUpdate = {
                        "id": product_id,
                        "title": titleRef.current.value,
                        "sub_title" : onlineDescRef.current.value,
                        "description" : descriptRef.current.value,
                        "quantity" : sizeRef.current.value,
                        "quantity_type" : presentationRef.current.value,
                        "brand" : brandRef.current.value,
                        "cost_price" : costpriceRef.current.value,
                        "price" : priceRef.current.value,
                        "manufacturer": supplierName,
                        "status" :  availa,
                      }

                      //console.log(tradeUpdate);

                      var dataTrade = JSON.stringify(tradeUpdate);
                      /////

                        var configUpdate = {
                            method: 'put',
                            url: CONSTANTS.LETSTRADE1 + 'product/update_product',
                            headers: {
                                'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN,
                                'Content-Type': 'application/json'
                            },
                            data : dataTrade
                        };
                        let execProd = await axios(configUpdate);
                        console.log(execProd);
                        console.log(execProd.data);
                      //////
                }else {
                    //letstradeupdate went wrong
                }

               //LETSTRADE END
               setProcessing(false);

        }catch(err){
            setMsgFeedback("Something went wrong, please try again later");
            setMsgClass("alert-danger");
            setProcessing(false);
            //console.log(err);
        }

    }

    const templateDraftStatusOption = (roleItem) => {
       //console.log(roleItem);
       //console.log("paaaaa")

        switch(roleItem) {
            case true:
              return <select ref={draftmodeRef} className="form-control wide100" >
                        <option value="true">Hidden</option>
                        <option value="false">Visible</option>
                    </select>;

            default:
                return <select ref={draftmodeRef} className="form-control wide100" >
                          <option value="false">Visible</option>
                          <option value="true">Hidden</option>
                    </select>;
          }
        //setSelectedPhotoInfo(rowData);
      }


  return (
    <div className="block-comp">
        <Dialog header="Photo Edit"  visible={showDialPhoto} onHide={() => setShowDialPhoto(false)} style={{ width: '50vw' }}
            footer={
            <div>

                <Button className="btn btn-picky" label="Close" onClick={() => setShowDialPhoto(false)} />
            </div>
            }>
                  <div className="modal-body-content">
                     <div className="ph-cont">

                        <div className="picture-quick-edit ">

                            <button className="btn btn-picky btsm1" disabled={processing} onClick={deleteThisField}>Delete</button>

                            <form onSubmit={handleFormUpdatePhoto}>
                                <div className="form-group">
                                      <div className="lbldesc">Barcode:</div>
                                      <input type="text" className="form-control wide100" ref={barcodeRef} defaultValue={selectedPhotoInfo.barcode} readOnly={true} required/>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Title:</div>
                                      <input type="text" className="form-control wide100" ref={titleRef} defaultValue={selectedPhotoInfo.title} required/>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Online Description:</div>
                                      <textarea className="form-control wide100 heightf100" ref={onlineDescRef}  defaultValue={selectedPhotoInfo.onlineDesc}></textarea>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Description:</div>
                                      <textarea className="form-control wide100 heightf150" ref={descriptRef}  defaultValue={selectedPhotoInfo.description}></textarea>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Brand:</div>
                                      <input type="text" className="form-control wide100" ref={brandRef} defaultValue={selectedPhotoInfo.brand} />
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Size:</div>
                                      <input type="text" className="form-control wide100" ref={sizeRef} defaultValue={selectedPhotoInfo.size} />
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Unit:</div>
                                      <select className="form-control wfull" ref={unitRef}>
                                            <option className="opt" value="" ></option>
                                             {unitOptions.map((item, i) => (
                                               <option key={i} value={item} defaultValue={item === selectedPhotoInfo.unit}>{item}</option>
                                              ))}
                                       </select>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Presentation:</div>

                                      <input type="text" className="form-control wide100" ref={presentationRef}   defaultValue={selectedPhotoInfo.presentation}/>
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Cost Price:</div>
                                      <input type="text" className="form-control wide100" ref={costpriceRef} defaultValue={selectedPhotoInfo.cost_price} />
                                </div>
                                <div className="form-group">
                                      <div className="lbldesc">Price:</div>
                                      <input type="text" className="form-control wide100" ref={priceRef} defaultValue={selectedPhotoInfo.price} />
                                </div>

                                <div className="form-group">
                                      <div className="lbldesc">Draft Status:</div>
                                      {templateDraftStatusOption(selectedPhotoInfo.draftmode)}
                                </div>

                                <div className="form-group">
                                      <div className="button-execs">
                                            <button type="submit" className="btn btn-picky mr-2" disabled={processing}>Update</button>
                                       </div>
                                </div>
                            </form>
                            <div className="">
                                {
                                  msgFeedback && (
                                    <div className={"alert-area " + msgClass}>
                                        {msgFeedback}
                                    </div>
                                  )
                                }
                          </div>
                        </div>

                     </div>
                       {
                          processing && (
                              <div className="img-source">
                              <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                              </div>
                          )
                      }
                  </div>
            </Dialog>
    </div>
  )
}

export default DlgEditPhoto
