import React, { useContext, useRef, useState } from 'react'
import './topnavbar.css';
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faGear, faHouseUser, faAddressCard, faArrowRightFromBracket , faBolt} from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from "../../context/AuthContext";
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";


function Tobnavbar() {
  const { user }                          = useContext(AuthContext);
  const [msgErr, setMessageErr]           = useState("");
  
  const supplierCodeRef                   = useRef();
  const navigate                          = useNavigate();

  const perfomLogOut = () =>{
      console.log("Logout");
      localStorage.removeItem("user_picknic2");
      document.location.href="/login";
  }

  const approveThisAccount = async () => {
    console.log("TOPBAR V1");
   
    if(supplierCodeRef.current.value.length > 3){
          try{
            setMessageErr("")
              const userAcc = {
                "userid" : user._id,
                "supplierid": user.extras[0].supplierId,
                "suppliernm": user.extras[0].supplierName,
                "code":supplierCodeRef.current.value
              }
              
            console.log(userAcc);
             const approveData = await axios.put(CONSTANTS.API_URL +"suppliers/verifyapprove", userAcc, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
             })

             //console.log(approveData);
             //console.log(approveData.data);
             if(approveData.data.status === "401"){
               setMessageErr(approveData.data.message)
             }else {
              
                setMessageErr("");
                if(approveData.data.updateUser.approve){
                  user.approve = true;
                  localStorage.setItem("user_picknic2", JSON.stringify(user));
                  navigate("/", { replace: true });
                }else {
                  console.log("Inner Problem.");
                  setMessageErr("Something went wrong. Please try again later.")
                }
                
             }
          }catch(erCode){
            console.log(erCode);
            console.log("Errror Approve Account");
            setMessageErr("Something went wrong. Please try again later.")
          }
    }else {
      setMessageErr("Code is incomplete.")
    }
  }
  return (
    <div className="nav-bar">
        <div className="src-item"></div>
        <div className="content-nav">
          {
            /*            <span className="sp-item"> <Link to="/settings" className="link-nav-item-top"><FontAwesomeIcon icon={faGear} /></Link> </span>
            */}
            <Dropdown>
              <Dropdown.Toggle variant="success profile-ps" id="dropdown-basic">
                <div className="image-profile-container">
                  {(user.profilePic === '-' 
                      ? <div className='dsh-profile' style={{ 
                          backgroundImage: `url(${CONSTANTS.IMAGE_PROFILE})` 
                        }}></div> 
                      : 
                        <div className="dsh-profile" style={{
                          backgroundImage: `url(${user.profilePic})` 
                        }}>

                        </div>)}
                </div>
              </Dropdown.Toggle>

            <Dropdown.Menu>
              {(user.profilePic !== '-' ) && <div className="dash-large-profile" style={{backgroundImage: `url(${user.profilePic})` }}></div>}
              <div className="title-dash-name">
                    {user.name}  {user.surname} 
              </div>
              <div className="title-dash-pro">
                    {user.profession}  
              </div>
              <div className="list-nav-item pdspace">
              <Dropdown.Item href="/profile"><span className="navicon"><FontAwesomeIcon icon={faAddressCard} /></span> <span className="nav-i-text">Profile</span></Dropdown.Item>
              <Dropdown.Item href="/"><span className="navicon"><FontAwesomeIcon icon={faHouseUser} /></span> <span className="nav-i-text">Home</span></Dropdown.Item>
              <Dropdown.Item href="/knowledge-base"><span className="navicon"><FontAwesomeIcon icon={faBolt} /></span> <span className="nav-i-text">Knowledgebase</span></Dropdown.Item>
              </div>
              <div className="log-box">
                <button onClick={perfomLogOut}  className="btn btn-logout"><span><FontAwesomeIcon icon={faArrowRightFromBracket}/></span> <span className="bg-logout-text">Log out</span></button>
              </div>
            </Dropdown.Menu>
            
          </Dropdown>
            
        </div>
        {
        /*
        If the user is not approved then they neeed to get a key from the supplier or catelogue host then from there they can approve themselves
        otherwise thte supplier or catelogue person will approve them. 
        */
        (!user.isAdmin && !user.approve ) && (
          <div className="no-approve-outer">
            <div className="not-approved-box">
              <h2 className="text-center">You are not yet approved</h2>
               <div className="conte-approve">
                {
                  user.extras[0].supplierName && (

                    <div className="">
                        <div className="form-group">
                            <p>{user.extras[0].supplierName}</p>
                            <div className="lbldesc">Supplier Approval Code*: </div>
                          <input type="text" className="form-control" ref={supplierCodeRef} placeholder="Enter Approval Code" />
                          <p>You can request that a registered supplier gives you an approval code to enter in.</p>
                        </div>
                        <div className="form-group pdtpbt10">
                            <button className="btn btn-picky" onClick={() => approveThisAccount()}>Approve Account</button>
                        </div>
                        {
                          msgErr && (
                            <div className="alert alert-danger">
                              {msgErr}
                            </div>
                          )
                        }
                  </div>
                  )
                }
               
               <p> You could contact us to request for your account to be approved. <a href="traffic@cjmarketing.co">traffic@cjmarketing</a></p>

                  <div className="log-box-mini">
                    <button onClick={perfomLogOut}  className="btn btn-logout"><span><FontAwesomeIcon icon={faArrowRightFromBracket}/></span> <span className="bg-logout-text">Log out</span></button>
                  </div>
               </div>
            </div>
          </div>
        )
        }
    </div>
  )
}

export default Tobnavbar