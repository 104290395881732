import React, { useEffect, useRef, useState  } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
 

function DlgCatalogueProductMigrate({showProductsMigrate, setShowProductMigrate, currentCatalogue, personAdmin}){

    const [processing, setProcessing]                            = useState(false);
    const [catalogListMG, setCatalogListMG]                      = useState([]);
    const [msgFeedback, setMsgFeedback]                          = useState("");
    const [msgClass, setMsgClass]                                = useState("");
    const selectedCatIdRef                 = useRef();


    useEffect(() => {
        collectCatalogueList();

    },[])

 

    const collectCatalogueList = async () => {

        try{

            const resCatList = await axios.get(CONSTANTS.API_URL +"catalogues/list", {
                    headers: {
                        token: "Bearer "+ personAdmin.accessToken
                    }
                });

 
            setCatalogListMG(resCatList.data);

        }catch(err){
            console.log(err);
        }

    }

 

    async function handleFormMigrateBarcodes(e) {

        e.preventDefault();  
        setProcessing(true);        

        try{


            const migrateObject = {
                    "migrateto" : selectedCatIdRef.current.value,
                    "migratefrom": currentCatalogue._id
                }

                const suprep = await axios.put(CONSTANTS.API_URL+"catalogues/migrate/barcodes", migrateObject, {
                    headers: {
                        token: "Bearer "+ personAdmin.accessToken
                    }
                });

            setMsgFeedback("Thank you, the catalogue " + suprep.data.title + " has received the migration.")
            setMsgClass("alert-success");
            setProcessing(false);  

        }catch(err){

            console.log(err);
            setMsgClass("alert-danger")
            setMsgFeedback("Something went wrong, please try again later");
            setProcessing(false);  

        } 
    }

  return (
    <div className="block-comp">
       <Dialog header="Migrate Catalogue" visible={showProductsMigrate} onHide={() => setShowProductMigrate(false)} style={{ width: '50vw' }}
            footer={
            <div>            
                <Button className="btn btn-picky" label="Close" onClick={() => setShowProductMigrate(false)} />
            </div>

            }>
                <div className="modal-body-content">
                    <div className="picture-quick-edit ">
                        <div className="search-sup">
                        <form onSubmit={handleFormMigrateBarcodes}>
                                    <div className="form-group">
                                        <div className="lbldesc">Select the Catalogue:</div>

                                        <select type="text" className="form-control wide100" ref={selectedCatIdRef}>

                                           {

                                                catalogListMG.map((cataItem, index) => {

                                                    if (cataItem._id !== currentCatalogue._id) {

                                                        return <option value={cataItem._id} key={index}>{cataItem.title}</option>

                                                    }

                                                })

                                           }
                                        </select>
                                    </div>
                                    <div className="button-execs">
                                        <button type="submit" className="btn btn-picky btn-full" disabled={processing}>MIGRATE</button>        

                                    </div>

                            </form>

                                {

                                    msgFeedback && (

                                    <div className={"alert-area " + msgClass}>

                                        {msgFeedback}

                                    </div>  

                                    )

                                }
                            </div>
                            <div className="text-500 mb-5">
                                Send the current list of barcodes to another catalogue.
                            </div>
                    </div>
    
                    {

                        processing && (

                            <div className="img-source">

                            <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />

                            </div>

                        )

                    }
            </div>
        </Dialog>
    </div>
  )
}

 

export default DlgCatalogueProductMigrate