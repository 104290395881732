
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext';
import * as CONSTANTS from "../CONSTANTS";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft, faCircleArrowRight, faGripHorizontal, faList, faSearch, faClose } from '@fortawesome/free-solid-svg-icons';
import ListTable from '../components/ListTable';
import { useNavigate } from "react-router-dom";
import DlgSearchPicture from '../components/DlgSearchPicture';

function MediaList() {

    const [datara, setDatara] = useState([]);
    const [page, setPageNumber] = useState(1);
    const [pageItems, setPageItems] = useState(1);
    const [plimit, setPLimit]                      = useState(40);
    const [pTotalSet, setPTotalSet]                = useState(20);
    const [totalProducts, setTotalProducts]        = useState(0);
    
    const [displayType, setDisplayType]            = useState("grid-layout");
    const [searchMToggle, setSearchMToggle]        = useState(false);
    const [q, setQue]                              = useState([]);

    const [processing, setProcessing]              = useState(false);
    const [searchProcess, setProcess]              = useState(false);
    const [searchResults, setSearchResults]        = useState([]);
    const [srchFieldMin, setSrchFieldMin]          = useState(3);

    const navigate = useNavigate()

    const { user } = useContext(AuthContext);

    useEffect(() => {

      if(!user.isAdmin){
        navigate("/access-denied");
      }
        
      getPhotos();
    }, [page])

    const getPhotos = async () => {
       // console.log("LIbrary")
       // console.log(user);
       setProcessing(true);
        try {
            //console.log("Coaching...")
            //console.log(CONSTANTS.API_URL+"pictures/free?page="+page+"&limit="+plimit)
            const res = await axios.get(CONSTANTS.API_URL +"pictures/free?page="+page+"&limit=" + plimit);
            //console.log(res);
            //console.log(res.data);
            setDatara(res.data);
            setPageItems(res.data.pictures.length)
            setTotalProducts(res.data.total);
            setPTotalSet(Math.ceil(res.data.total / plimit))
            //console.log("+++++++++++++++++++++++++++");
            setProcessing(false);
          } catch (err) {
            console.log(err);
            console.log("ERRRRRRR");
            setProcessing(false);
            if(err.message === "Request failed with status code 403"){
              localStorage.removeItem("user_picknic2");
              document.location.href="/login";
             }
          }
        
    }

    const prevClickFunction = async () => {
      //console.log("Previous");
      const pagePrev = page - 1;
      setPageNumber(pagePrev)
    }  
    
    const nextClickFunction = async () => {
      //console.log("Next");
      const pageNext = page + 1;
      setPageNumber(pageNext)
    }  
    const makeListDisplay = () => {
      setDisplayType("list-layout");
    }
    const makeGridDisplay = () => {
      setDisplayType("grid-layout");
    }

    const onSearchDialogue = () =>{
      setSearchMToggle(true);
    }
    const offSearchDialogue = () => {
      setSearchMToggle(false);
    }

    const searchForProduct = async (e) => {
      //console.log(e.target.value);
      setQue(e.target.value);
      //console.log(e.target.value.length);
      if(e.target.value !== ""){
        //console.log("#############################");
        //console.log("Input is not empty");
        if(e.target.value.length > 2){
          setSrchFieldMin(0);
          try {
          
            setProcess(true);               
                  const res = await axios.get(CONSTANTS.API_URL +"pictures/search?searchfield="+ e.target.value , {
                    headers: {
                      token: "Bearer " + user.accessToken
                  }
                  });
                  //console.log(res);
                  console.log(res.data);
                  setSearchResults(res.data);                
                  setProcess(false);
                  //console.log("+++++++++++++++++++++++++++");
          } catch (err) {
            console.log(err);
            console.log("ERRRRRRR");
            setProcess(false);
          }
          //////////////////
        }else {          
          setSrchFieldMin(3 - e.target.value.length);          
          //console.log(srchFieldMin);
        }
        
      }else {    
        setSrchFieldMin(3);
      }
      //()
    }

  return (
    <div className="main-standard">
      <h2 className="lbl-title-page">Media</h2>
      <div className="card">
        <div className="admin-edt-bar">
            <div className="flexme space-far">
                <div className="lb-fact-item">
                  <span className="weightC">{totalProducts} </span>total product images
                </div>
                <div className="lb-fact-item ">
                  {
                    displayType == "grid-layout" ?
                    <div className="but-options flexme">
                      <button className="no-button-style lay-default search-button" onClick={onSearchDialogue}>{<FontAwesomeIcon icon={faSearch} />}</button>
                      <button className="no-button-style lay-default selectedOptioin">{<FontAwesomeIcon icon={faGripHorizontal} />}</button>
                      <button className="no-button-style lay-default " onClick={makeListDisplay}>{<FontAwesomeIcon icon={faList} />}</button>
                    </div>
                    :
                    <div className="but-options flexme">
                      <button className="no-button-style lay-default search-button" onClick={onSearchDialogue}>{<FontAwesomeIcon icon={faSearch} />}</button>
                      <button className="no-button-style lay-default " onClick={makeGridDisplay}>{<FontAwesomeIcon icon={faGripHorizontal} />}</button>
                      <button className="no-button-style lay-default selectedOptioin">{<FontAwesomeIcon icon={faList} />}</button>
                    </div>
                  }
                </div>
              
            </div>
           
        </div>
        <div className="griding-box">
          {datara.pictures && (
            <ListTable datapics={datara.pictures} displayTp={displayType}/>
          )}
           {
              processing && (
                  <div className="img-source">
                     <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc profixed" />
                  </div>
              )
            }
        </div>
       
            <DlgSearchPicture 
                searchMToggle={searchMToggle}
                setSearchMToggle={setSearchMToggle}
                members={user}
            />

        <div className="admin-edt-bar paginate-bar">
              <div className="show-res">
                  showing page {page}  of {pTotalSet}
              </div>
              <div className="pagi-block">
                { 
                  datara.prev && (
                    <div className="but-item">
                      {datara.prev.navi === "on" ? <button className="btn-form" onClick={prevClickFunction}> <span className="ls-spot"> <FontAwesomeIcon icon={faCircleArrowLeft} /></span></button> : ""}
                    </div>
                  )
                }
                { 
                  datara.next && (
                    <div className="but-item">
                      {datara.next.navi === "on" ? <button className="btn-form" onClick={nextClickFunction}> <span className="ls-spot"> <FontAwesomeIcon icon={faCircleArrowRight} /></span></button> : ""}
                    </div>
                  )
                }
              </div>
        </div>
      </div>
    </div>  
  )
}

export default MediaList