import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import * as CONSTANTS from "./../CONSTANTS";
import axios from "axios";

function DlgLinkPicturesCat({showLinkPicturesDialogue, setShowLinkPicturesDialogue, catid, person, setListUdate}) {

    const [loading, setLoading]                                                           = useState(false);
    const [thePage, setThePage]                                                           = useState(1);
    const [theLimit, setTheLimit]                                                         = useState(40);
    const [pictureList, setPictureList]                                                   = useState([]);
    const [pictureUrl, setPictureUrl]                                                     = useState("");
    const [nextString, setNextString]                                                     = useState(""); 

    const [checkedOnProducts, setCheckedOnProducts]                                       = useState([]);
    const [classMsg, setClassMsg]                                                         = useState();    
    const [messageAlert, setMessageAlert]                                                 = useState();

 
    useEffect(() => {

        if(showLinkPicturesDialogue){

            getAllCurrentPicturesShort();

        }

    },[showLinkPicturesDialogue]);


    const getAllCurrentPicturesShort = async () => {
        setLoading(true);

        try{

            const res = await axios.get(CONSTANTS.API_URL + "pictures/compile/v2/list?page=" + thePage + "&limit=" + theLimit, {

                headers: {

                  token: "Bearer " + person.accessToken

                }

              });

              //console.log("{{ }}");
              setPictureList(res.data.pictures);
              setNextString(res.data.next.navi);

 

            setLoading(false);

        }catch(err){

            console.log("Shareen");
            console.log(err);
            setLoading(false);

        }

      }

 

      const handleNextAppendLink = async () => {

        setLoading(true);

        try{

           const countPage = thePage + 1;

           setThePage(countPage);

           const res = await axios.get(CONSTANTS.API_URL + "pictures/compile/v2/list?page=" + countPage + "&limit=" + theLimit, {
                    headers: {
                    token: "Bearer " + person.accessToken
                    }
                });              

            setPictureList((prevPictureList) => [...prevPictureList, ...res.data.pictures]);
            setNextString(res.data.next.navi); 

            setLoading(false);

        }catch(err){

            console.log(err);

            setLoading(false);

        }

      }

 

      const handleChangeChecked = (consume) => {

        setCheckedOnProducts((prevCheckedOnProducts) => {

          const barcodeIndex = prevCheckedOnProducts.indexOf(consume.barcode);

          if (barcodeIndex !== -1) {

            // Barcode exists, remove it
            return prevCheckedOnProducts.filter((barcode) => barcode !== consume.barcode);

          } else {

            // Barcode doesn't exist, add it
            return [...prevCheckedOnProducts, consume.barcode];

          }
        });
      };
   

      const handleAddingCatalogue = async () => {
        setLoading(true);

        try{

           

            const contentAdding = {
                "catalogueid" : catid,
                "listing" : checkedOnProducts
            }

           const res = await axios.put(CONSTANTS.API_URL + "pictures/add/catalogue/v2/", contentAdding, {

                    headers: {
                           token: "Bearer " + person.accessToken
                    }
                });


                setMessageAlert(res.data.message);

                if(res.status === 200){
                    setClassMsg("alert-success");
                    setListUdate( prev => prev + 1);
                }else {
                    setClassMsg("alert-warning")
                }

            setLoading(false);

        }catch(err){

            setLoading(false);
            //setMessage(res.data.message);
               setMessageAlert("Something went wrong, please try again later.")
               setClassMsg("alert-danger");                

        }

      }


      const handleRemovingCatalogue = async () => {

        try{

            const contentRemove = {
                "catalogueid" : catid,
                "listing" : checkedOnProducts
            }
            //console.log(contentRemove);
 
            const res = await axios.put(CONSTANTS.API_URL + "pictures/remove/catalogue/v2/", contentRemove, {
                headers: {
                token: "Bearer " + person.accessToken

                }

            });
 

        setMessageAlert(res.data.message);

            if(res.status === 200){
                setClassMsg("alert-success");
                setListUdate( prev => prev + 1);
            }else {
                setClassMsg("alert-warning")
            }

        }catch(err){
        
            setMessageAlert("Something went wrong, please try again later.")
            setClassMsg("alert-danger");
        }
      }


  return (
    <Dialog header="Link Pictures" visible={showLinkPicturesDialogue} style={{ width: '75vw' }} modal={true} onHide={() => setShowLinkPicturesDialogue(false)}
        footer={
            <div>
                <Button className="btn btn-picky" label="Close" onClick={() => setShowLinkPicturesDialogue(false)} />
            </div>
        }>

        <div className="dialgue-body">
            <div className="pd10">
            {

                checkedOnProducts.length > 0 && (<h4 className="">{checkedOnProducts.length} Total selected.</h4>)
            }
        </div>
        <div className="pd10">
            {
                messageAlert && (
                    <div className={"alert " + classMsg}>

                        {messageAlert}
                    </div>

                )

           }
        </div>
        <div className="flexme">
            <div className="flx-70">
                <div className="transition-stripe">
                {

                        pictureList &&
                        pictureList.length > 0 && (
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>NO</th>
                                        <th>#</th>
                                        <th>Barcode</th>
                                        <th>Title</th>
                                        <th>Schedule</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        pictureList.map((picitem, index) => {
                                            return <tr key={index}>
                                                        <td> {index + 1}</td>
                                                        <td> <input type="checkbox" onChange={() => handleChangeChecked(picitem)} /></td>
                                                        <td>{picitem.barcode}</td>
                                                        <td>{picitem.title}</td>
                                                        <td>{picitem.schedule}</td>
                                                        <td>
                                                            <button className="btn btn-picky btn-small-but" onClick={() => setPictureUrl(picitem.imgurl)}> View</button>
                                                        </td>
                                                    </tr>

                                        })
                                    }
                                </tbody>
                            </table>
                        )
                    }
                </div>

            </div>
            <div className="flx-30">
                <div className="img-block">
                    {
                        pictureUrl && (<img
                                src={pictureUrl}
                                className="img-responsive img-space"
                                loading="lazy"
                                srcset={`${pictureUrl}?width=100 100w,${pictureUrl}?width=200 200w `}
                                sizes="(max-width: 992px) 100vw, 500vw"/>)

                    }
                </div>
            </div>
        </div>
        <div className="pd20">
           <div className="flexme">
              {
                    nextString &&
                    nextString === "on" && (
                        <div className="navigation-strip">

                            <button className="btn btn-picky btsm1 mr-2" onClick={() => handleNextAppendLink()}>Next</button>                            
                        </div>
                    )
              } 

              {

                checkedOnProducts.length > 0 && (<button className="btn btn-green btsm1 mr-2" onClick={() => handleAddingCatalogue()}>Add to Catalogue</button>)

            }

            {

                checkedOnProducts.length > 0 && (<button className="btn btn-danger btsm1 mr-2" onClick={() => handleRemovingCatalogue()}>Remove from Catalogue</button>)

              }

           </div>
        </div>
        {
            loading && (
                <div className="img-source">
                    <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                </div>
            )
        }
    </div>
    </Dialog>
  )
}

 

export default DlgLinkPicturesCat