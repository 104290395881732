import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
/*

POP UP FOR EDITING CATALOGUE PRODUCT PRICE AND ITS ASSIGNEMT TO

A CATOGORY

*/

function DlgCatalogueProductEdit({showCatsProductEdit, setShowCatsProductEdit, catid, catalogue, product, person} ) {
    const [processing, setProcessing]                       = useState(false);

    const [replaceCategory, setReplaceCategory]             = useState(true);
    const [catMess, setCatMess]                             = useState();
    const [msgClass, setMsgClass]                           = useState();
    const pricingRef                                        = useRef();

    const classificateRef                                   = useRef();   
 

    async function handleSavePricingAssign(e) {
        e.preventDefault();  
        setProcessing(true);  

        try{
        
                ///////////////////******** FORM WORK */

                const pricing =  pricingRef.current.value;

                const catalogItem = {
                    "productid": product._id,
                    "catalogueid": catid,
                    "pricing": pricing

                }
              

                const response = await axios.post(CONSTANTS.API_URL+"catalogues/product/addpricing", catalogItem, {

                    headers: {

                        token: "Bearer "+ person.accessToken

                    }

                });


               pricingRef.current.value = "";

         

            setProcessing(false);

        } catch (err){

          console.log("Something went wrong ");

          console.log(err);

          setProcessing(false);

        }      

    }

    async function handleSavAssignCategory(e) {

        e.preventDefault();  
        setProcessing(true);  

        try{

           

                ///////////////////******** FORM WORK */

                const classify =  classificateRef.current.value;

                const catalogItem = {
                    "catalogueid": catid,
                    "productid": product._id,
                    "categoryname": classify,
                    "replace" : replaceCategory

                }

                const response = await axios.post(CONSTANTS.API_URL+"catalogues/addcategory/classification/product", catalogItem, {
                    headers: {
                        token: "Bearer "+ person.accessToken,
                        'Content-Type': 'application/json'
                    }
                });


                setCatMess("Category added to Picture");
                setMsgClass("alert-success"); 

                setTimeout(function() {

                    setCatMess("");

                    setMsgClass("");

                }, 4500);

 

            setProcessing(false);

        } catch (err){

          setCatMess("Something went wrong, please try to get ");

          setMsgClass("alert-danger");
          console.log(err);
          setProcessing(false);

        }      

    }

 

    const handleChangeReplace = event => {
        setReplaceCategory(current => !current);

    }

 

    return (
        <div className="block-comp">
          <Dialog header="Product Edit of a Catalogue" visible={showCatsProductEdit} onHide={() => setShowCatsProductEdit(false)} style={{ width: '50vw' }}

            footer={

            <div>

               <Button className="btn btn-picky" label="Close" onClick={() => setShowCatsProductEdit(false)} />

            </div>

            }>

            <div className="modal-body-content">
               <div className="prod-infor">
                 {
                    product && (
                        <table className="table">
                        <tbody>

                            <tr>

                                <td>

                                    <div className="pro-small ">Barcode</div>

                                    <strong>{product.barcode}</strong>

                                </td>

                                <td>
                                    <div className="pro-small ">Title</div>
                                    <strong>{product.title}</strong>
                                </td>
                                <td rowspan="2">
                                    <img src={product.imgurl} alt="image" className="product-img-edit" />
                                </td>
                            </tr>
                            <tr>

                                <td colspan="2">

                                   <div className="pro-small ">Online Description</div>

                                    {product.onlineDesc}

                                </td>                              
                            </tr>
                            <tr>
                                <td colspan="3">
                                   <div className="pro-small ">Full Description</div>
                                    {product.description}
                                </td>                              
                            </tr>

                            {

                                product.storePrice && product.storePrice.length > 0 && (
                                        <tr>
                                            <td colSpan="3">
                                                <div className="pro-small ">Store Price for Catalogue ID: {catid}</div>
                                                <ul>
                                                    {

                                                    product.storePrice

                                                        .filter(priceData => priceData.catalogueid === catid)

                                                        .map((filteredPrice, index) => (

                                                            <li key={index}>Price: {filteredPrice.price}</li>

                                                        ))}

                                                </ul>

                                            </td>

                                        </tr>

                                    )

                             }

                             {

                             product.classification && product.classification.length > 0 && (

                                   <tr>

                                        <td colSpan="3">

                                            <div className="pro-small ">Category Class</div>

                                            <ul>

                                                {product.classification.map((category, index) => (

                                                    <li key={index}>{category}</li>

                                                ))}

                                            </ul>

                                        </td>

                                    </tr>

                                )}

                        </tbody>
                      </table>
                    )
                 }

                 <div className="cat-info-work">
                    <h4>Catalogue Details</h4>

                    <div className="flexme">
                        <div className="spaceshare2 pd10">
                          <div className="frm-box-center">
                            <form onSubmit={handleSavePricingAssign}>
                                    <div className="form-group">
                                        <div className="lbldesc">Add the Price*: </div>
                                        <input type="text" ref={pricingRef} className="form-control w80" required/>
                                    </div>

                                    <div className="form-group">
                                        <button type="submit" className="btn btn-picky" disabled={processing}>Save Price</button>

                                    </div>                            

                                </form>

                          </div>
                        </div>

                        <div className="spaceshare2 pd10">
                            <div className="frm-box-center">
                                <form onSubmit={handleSavAssignCategory}>
                                       <div className="form-group">
                                            <input
                                                type="checkbox"
                                                value={replaceCategory}
                                                onChange={handleChangeReplace}
                                                id="replacecategory"
                                                name="replacecategory"
                                                defaultChecked={true}
                                                />
                                             <label className="pdlf10"> Replace Category</label>
                                       </div>
                                        <div className="form-group">
                                            <div className="lbldesc">Select Catagory: </div>

                                            {

                                                  catalogue && catalogue.classification && Array.isArray(catalogue.classification) && catalogue.classification.length > 0 && (

                                                    <select className="form-control w80" ref={classificateRef}>

                                                            {catalogue.classification.map((classify, i) => {
                                                            return <option className="opt" value={classify} key={i}>{classify}</option>
                                                            }) }

                                                    </select>

                                                )

                                            }

                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-picky" disabled={processing}>Save Category</button>
                                        </div>
                                    </form>

                                
                                    {
                                        catMess && (
                                            <div className={"alert-area " + msgClass}>
                                            {catMess}
                                            </div>
                                        )

                                    }
                            </div>
                        </div>
                    </div>
 

                        {

                            processing && (
                                <div className="img-source">
                                   <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                                </div>
                            )
                        }
                 </div>
               </div>
            </div>
         </Dialog>
      </div>
    )
}
 

export default DlgCatalogueProductEdit