import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';


function TBVSalesCategories({person}) {

    const [cataListm, setCataList]                               = useState([]);
    const [selectedCatalogue, setSelectedCatalogue]              = useState(null);
    const [globalFilter, setGlobalFilter]                        = useState('');
    const [msgSuc, setMsgSuc]                                    = useState("");
    const [msgErr, setMsgErr]                                    = useState('');
    const [categSearched, setCategSearched]                      = useState(false);
    const [processing, setProcessing]                            = useState(false);
    const [cateCount, setCateCount]                              = useState(0);

    useEffect(() => {
      getSalesLayerCatalogueList();
    },[]);

    const headerTable = (
        <div className="table-header flex justify-content-between">
          <h4 className="mx-0 my-1">Category ({cateCount})</h4>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search Categories..." />
          </span>
        </div>
      );

    const categoryTpeTemplate = (rowData) => {
       // console.log(rowData);
        if(rowData.parentCatId === "0"){
            return <div className="rt-cat">Main</div>
        }else {
            //console.log(rowData.parentCatId);
            return <div className="rt-cat">Sub</div>
        }
       // return <img src={rowData.imgurl} alt="image" className="product-image" width={80} />;
      }
      /////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////
      const getSalesLayerCatalogueList = async () => {
        setProcessing(true);
        try{

              const resCat = await axios.get(CONSTANTS.SALES_LAYER_CAT_FTCH);
              //console.log("Diggging In");
              //console.log(resCat.data.data);
              //console.log(resCat.data.status[0]);
              setCateCount(resCat.data.data.length)
              setCataList(resCat.data.data);
              setCategSearched(true);
            setProcessing(false);
        }catch(ers){
            console.log(ers);
            console.log("RRR");
            setProcessing(false);
        }
      }

      const transferToCategories = async () => {
        setProcessing(true);
        try{
         //console.log( "Taffies" );
         //console.log(cataListm);

         let arrCats             = [];
         let arrCatsLetsTrade    = [];
         let newCats             = [];
         let newCatsLetsTrade    = [];
         let rootCat    = cataListm.filter(cat => {
          return cat.parentCatId === "0"
        });

        //console.log( "Flieght" );
        //console.log(rootCat);

        rootCat.forEach(element => {

          let subCategories = cataListm.filter(subCat => {
            return element.catId === subCat.parentCatId
          });
          //console.log("Brrrr Brrrrrrr Rrrrrrrrrr ");
          //rootCat[0].children.push(subCategories);
          //element["sub_categories"] = subCategories;
          //Saleslayer category
          if(subCategories.length > 0){
            //console.log(subCategories.length );
            arrCats.push(subCategories);
          }
          
        });

        //console.log( "# Liverpool #" );
        newCats = arrCats[0];
        //console.log(newCats);
        newCats.forEach(el => {

          /*
          let subSubCategories = cataListm.filter(subCat => {
            return el.catId === subCat.parentCatId
          });

          console.log("Sub Cats");
          console.log(subSubCategories);
          console.log("<------_____--------______------>");
          */
          let subSubCategoriesTrade = cataListm.filter(subCat => {
            return el.catId === subCat.parentCatId
          }).map(function(ob) {
             let sbOb ={
              "name" : ob.categoryName,
              "description": ob.categoryDescription,
              "image_url": "",
            }
            return  sbOb;
          });
          //console.log("########################################");
          //rootCat[0].children.push(subCategories);
 
          el["sub_categories"] = subSubCategoriesTrade;
          //Saleslayer category    
          el.extra_fields = []; 
         
          var ltTrade = {
             "name" : el.categoryName,
             "description" : el.categoryDescription,
             "image_url": "",
             "sub_categories": el.sub_categories,
          
          }
          newCatsLetsTrade.push(ltTrade);
        });

         console.log( "# PICNIC PACK #" );
         console.log(newCats);
         console.log( "# LETSTRADE PACK #" );
         console.log(newCatsLetsTrade);
        // console.log(newCats.length);
        
         
          /*
          const d = new Date();
          let time = d.getTime();
          console.log("Stream effort Live " + time);
          console.log(streamer.data);
          */


       
  
            //LETS TRADE EXECUTION
            var dataTrade = JSON.stringify(newCatsLetsTrade);

            var configTrade = {
              method: 'post',
              url: CONSTANTS.LETSTRADE1 + "product/add_categories",
              headers: { 
                'Authorization': 'Bearer '+ CONSTANTS.LTTOKEN, 
                'Content-Type': 'application/json'
              },
              data : dataTrade
            };

            const ltradeObject = await axios(configTrade);
              //console.log("LETTRADE FEED BACK AFTER SENDING CATEGORIES");
            console.log(ltradeObject.data);
              console.log("[][][][][][][][][][][][][][][][]");
              console.log("[][][][][][][][][][][][][][][][]");
              console.log("[][][][][][][][][][][][][][][][]");
                //setMsgSuc("Category list transferred to picnic successfully.");
                var rootCatContent = ltradeObject.data.content;
                let subCatTradetoPic = [];
                rootCatContent.forEach(specificEl => {
                 
                  var sbCat = {

                  }
                  var cat_valog = [];
                    if(specificEl.sub_categories.length > 0){
                      console.log("Not empty");
                      for (let i = 0; i < specificEl.sub_categories.length; i++) {
                      
                        sbCat = {
                          "catId" : specificEl.sub_categories[i]._id,
                          "name" : specificEl.sub_categories[i].name,
                          "description" : specificEl.sub_categories[i].description,
                          "image_url" : specificEl.sub_categories[i].image_url,
                        }
                       
                        cat_valog.push(sbCat);
                      }
                    }else {
                      console.log("This is empty")
                    }

                  var picAr = {
                    "catId" : specificEl._id,
                    "categoryName" : specificEl.name,
                    "categoryDescription" : specificEl.description,
                    "image_url" : "",
                    "sub_categories": cat_valog
                  }
                  
                  subCatTradetoPic.push(picAr);
                });
                console.log("====== *************************");
                console.log(subCatTradetoPic);

                var data = JSON.stringify(subCatTradetoPic);
                var config = {
                  method: 'post',
                  url: CONSTANTS.API_URL+"categories/streamer",
                  headers: { 
                    'token': "Bearer "+ person.accessToken,
                    'Content-Type': 'application/json'
                  },
                  data : data
                };
                
                //#######################{}{}{}{}{}{}{}{}{}{}{}{}{}{}{}{} 
                const streamer = await axios(config);
                console.log("###### <0> ######");
                console.log(streamer.data);
              console.log("[][][][][][][][][][][][][][][][]");
              console.log("[][][][][][][][][][][][][][][][]");
              console.log("[][][][][][][][][][][][][][][][]");
             
          
       
          setProcessing(false);
        }catch(errStream){
          console.log(errStream);
          setProcessing(false);
          setMsgErr("Something went wrong. Please try again later.")
        }
    }

    const transferToUnformatedSalesLayerCategories = async () => {
      setProcessing(true);
        try{
         //console.log( "Taffies" );
         console.log(cataListm);

         let arrCats             = [];
         let newCats             = [];
         let rotCat    = cataListm.filter(cat => {
          return cat.parentCatId === "0"
        });
     

        rotCat.forEach(element => {

          let subCategories = cataListm.filter(subCat => {
            return element.catId === subCat.parentCatId
          });
          console.log("SSSSSSSS SSSSSSSS SSSSSS ");
          //rotCat[0].children.push(subCategories);
          //element["sub_categories"] = subCategories;
          //Saleslayer category
          if(subCategories.length > 0){
            console.log("Sub Cats length" );
            console.log(subCategories.length );
            arrCats.push(subCategories);
          }
          
        });

        //console.log( "# Liverpool #" ); 
        newCats = arrCats[0];
        //console.log(newCats);

        newCats.forEach(el => {
            let subSubCategories = cataListm.filter(subCat => {
              return el.catId === subCat.parentCatId
            });

            console.log("Sub Cats");
            console.log(subSubCategories);
            console.log("<------_____--------______------>");
            //set sub categories
            el["sub_categories"] = subSubCategories;
        
        });
      

        console.log( "# ICE PACK #" );
        console.log(newCats);
        //console.log(newCats.length);
        
         
          var data = JSON.stringify(newCats);
          var config = {
            method: 'post',
            url: CONSTANTS.API_URL+"saleslayers/streamcatsx1",
            headers: { 
              'token': "Bearer "+ person.accessToken,
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          const streamer = await axios(config);
          console.log("###### <0> ######");
          console.log(streamer.data);

          setProcessing(false);
        }catch(errStream){
          console.log(errStream);
          setProcessing(false);
          setMsgErr("Something went wrong. Please try again later.")
        }
    }

  return (
    <div className="tv-box">
          {
            (cataListm.length > 0) ? (
              <>
                 <DataTable
                    value={cataListm}
                    header={headerTable}
                    paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    responsiveLayout="scroll"
                    globalFilter={(globalFilter) ? globalFilter : ''}
                    selection={selectedCatalogue}
                    onSelectionChange={e => setSelectedCatalogue(e.value)}
                    >
                    <Column field="catId" header="ID"></Column>                    
                    <Column field="categoryName" header="Category"></Column>
                    <Column field="categoryDescription" header="Desc"></Column>
                    <Column field="categoryReference" header="Ref"></Column>
                    <Column header="Type" body={categoryTpeTemplate}></Column>
                    <Column field="parentCategoryReference" header="Parent Cat"></Column>
                </DataTable>
                
                <div className="pdtb10">
                        <Button
                            className="btn btn-circle btsm1 mr-2"
                            label="Transfer to Categories"
                            onClick={() => transferToCategories()}
                          />
                      {
                          /*
                          <Button
                              className="btn btn-circle btsm1 mr-2"
                              label="Transfer to Unformated Categories"
                              onClick={() => transferToUnformatedSalesLayerCategories()}
                            />
                            */
                      }
                </div>
              </>
            )
            :
            (

                <div className="">
                 {
                  categSearched ? 
                      <h2>Nothing found</h2>
                      :
                      <div className="">Loading...</div>
                  }                
                </div>
            )
          }
          {
              msgErr && (
                  <div className="alert alert-danger">{msgErr}</div>
              )
          }
          {
            msgSuc && (
              <div className="alert alert-success">{msgSuc}</div>
            )
          }
          {
                processing && (
                    <div className="img-source">
                    <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                    </div>
                )
           }
    </div>
  )
}

export default TBVSalesCategories
