import React, { useEffect, useState } from 'react'
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faLinkSlash } from '@fortawesome/free-solid-svg-icons';
import './home.css';


function NoSupplierPics() {

    const [total, setTotal] = useState(0);

    useEffect(() => {
        getTheTotalNoSups();
    },[]); 

    const getTheTotalNoSups = async () => {

        try{

            const response = await axios.get(CONSTANTS.API_URL + "pictures/nosuppliers");
            setTotal(response.data.total)

        }catch(err){
            console.log("Err Totals NoSup");
            console.log(err);
        }

    }

  return (
    <div className="col-md-3">
        <div className="card flex-mg-space ">
            <div className="card-body widget-body">
                <div className="home-dash-item">
                    <div className="ico-title ico-tt-blue">
                       <FontAwesomeIcon icon={faLinkSlash} />
                    </div>

                    <h3 className="hdi-title">Pictures with no Supplier</h3>
                    <div className="numFeed">
                        {total}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )

}

export default NoSupplierPics