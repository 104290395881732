export const API_URL = "https://picknickapi2.herokuapp.com/api/";
//export const API_URL = "http://localhost:8585/api/";
//export const API_URL = "https://picnicapi-back2.onrender.com/api/"
export const PREVIEW_IMAGE = "https://cjmarketing.co/image_directory/preview.png";
export const IMAGE_PROFILE = "https://newclient.cjdsa.co.za/images/base/profile.jpg";
export const IMAGE_PREV_SIZE = "https://cjmarketing.co/image_directory/size_example.png"
export const LOGIN_BACKGROUND = "https://cjmarketing.co/image_directory/cam_picnnic.png"
export const EXCEL_IMPORT = "https://api.picnick.co.za/assets/example_sheet.png"
export const SMALL_PROCESSING = "https://api.picnick.co.za/assets/smallprocess.gif";
export const SECOND_PROCESSING = "https://newclient.cjdsa.co.za/images/base/loading-cogs.gif";
export const TOKEN_IMAGE = "https://api.picnick.co.za/assets/tokenCreation.png"
export const EMAIL_URL = "http://api.picnick.co.za/emailing/email.php?"
export const EMAIL_CODE = "picnickCutterX";
export const IMG_FOLDER       = "uploads";
export const SESSION_COOKIE   = "user_picknic2_live";
//export const SESSION_COOKIE = "user_picknic2_local";
export const LETSTRADE1           = "https://lets-trade-product-dev.eu-gb.mybluemix.net/";
export const LETSTRADEUAT         = "https://lets-trade-product-uat.letstrade.global/v2/";
export const LETSTRADEPROD        = "https://lets-trade-product-prod.letstrade.global/v2/";
export const SALES_LAYER_CAT_FTCH = "https://picnick.co.za/picnick.co.za/saleslayers/prodfetch.php?categolayer=x2daefasefada";
export const SALES_LAYER_PIC_FTCH = "https://picnick.co.za/picnick.co.za/saleslayers/prodfetch.php?prodlayer=x2daefasefada";
export const SALES_LAYER_SUP_FTCH = "https://picnick.co.za/picnick.co.za/saleslayers/prodfetch.php?supplierlayer=x2daefasefada";
export const SALES_LAYER_PIC_CATY = "https://picnick.co.za/picnick.co.za/saleslayers/prodfetch.php?prodtaglayer=tt7dtagef765";
export const LTTOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0NTlmZDg2NGI1NGQ3MDAwZTZkNGY5YSIsIm5hbWUiOiJBY2tlcm1hbnMiLCJ0eXBlIjoiRU5USVRZIiwiaWF0IjoxNjgzNjE5MjA2fQ.1PA1ge0iI4EkiuMGUcJlqenEDiV5wOiiSsBj1OXAufE";
export const LTTOKENP = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0NWEwMWQwM2UzM2NmMDA4ZjVhMzBjZiIsIm5hbWUiOiJBY2tlcm1hbnMiLCJ0eXBlIjoiRU5USVRZIiwiaWF0IjoxNjgzNjIwMzA0fQ.imExzDj4QSv4nQTypieXtzMxa1XKhuLuXaQ1EmXtnS0";
export const PICKYBIN = "https://picnick.co.za/picnick.co.za/cjcobolstock/reactcron.php?packerbin=ifPkyd77sdf8gaf";
